<template>
  <div class="cursor" :style="{
    'width': (catchedUp ? followerSize : size) + 'px',
    'height': (catchedUp ? followerSize : size) + 'px',
    'transition-duration': (catchedUp ? .7 : .1) + 's',
    'top': y + 'px',
    'left': x + 'px',
  }" />

  <div class="follower" :style="{
    'transition-duration': (catchedUp ? .7 : .5) + 's',
    'opacity': catchedUp ? 0 : 1,
    'width': (catchedUp ? followerSize * .8 : followerSize) + 'px',
    'height': (catchedUp ? followerSize * .8 : followerSize) + 'px',
    'top': followerY + 'px',
    'left': followerX + 'px',
  }" />

</template>

<script>
import {onMounted, onUnmounted, ref} from "vue";

export default {
  name: 'cursor',
  props: {
    size: {
      type: Number,
      default: 8,
    },
    followerSize: {
      type: Number,
      default: 48,
    },
    followerSpeed: {
      type: Number,
      default: .1,
    },
  },
  setup(props) {
    const x = ref(0)
    const y = ref(0)
    const catchedUp = ref(false)
    const followerX = ref(0)
    const followerY = ref(0)

    function updatePosition(e) {
      x.value = e.x
      y.value = e.y
    }

    function updateFollower() {
      followerX.value = followerX.value + props.followerSpeed * (x.value - followerX.value)
      followerY.value = followerY.value + props.followerSpeed * (y.value - followerY.value)
      catchedUp.value = Math.round(followerX.value) === Math.round(x.value) && Math.round(followerY.value) === Math.round(y.value)
    }

    onMounted(() => {
      window.addEventListener('mousemove', updatePosition);
      window.setInterval(updateFollower, 16)
    })
    onUnmounted(() => {
      window.removeEventListener('mousemove', updatePosition)
    })

    return {x, y, followerX, followerY, catchedUp}
  },
}
</script>

<style scoped>
.cursor {
  color: #1a1a1a;
  background-color: #eaeaea;
  position: fixed;
  pointer-events: none;
  border-radius: 50%;
  mix-blend-mode: difference;
  transform: translateY(-50%) translateX(-50%);
  transition-property: width, height;
  transition-timing-function: ease-in-out;
  z-index: 1000;
}

.follower {
  color: #1a1a1a;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  border-radius: 50%;
  mix-blend-mode: difference;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border: 1px solid #eaeaea;
  z-index: 998;
  transform: translateY(-50%) translateX(-50%);
  transition-property: opacity, width, height;
  transition-timing-function: ease-in-out;
}
</style>