<script setup>

import {defineProps, ref} from 'vue'
    import {VueperSlide, VueperSlides} from 'vueperslides'
  defineProps({
    password: String,
  })
    const images = ref([{
          image: '/traend/13444.webp',
          link: '/traend/13444.jpg',
        }, {
          image: '/traend/13445.webp',
          link: '/traend/13445.png',
        }, {
          image: '/traend/12.19.47.webp',
          link: '/traend/12.19.47.png',
        }, {
          image: '/traend/12.20.12.webp',
          link: '/traend/12.20.12.png',
        }, {
          image: '/traend/12.20.19.webp',
          link: '/traend/12.20.19.png',
        }, {
          image: '/traend/12.20.27.webp',
          link: '/traend/12.20.27.png',
        }, {
          image: '/traend/12.20.36.webp',
          link: '/traend/12.20.36.png',
        }, {
          image: '/traend/SoMe.webp',
          link: '/traend/SoMe.png',
        }])

</script>

<template>
  <div class="section">
    <h2>MWK - Baden-Württemberg (Pitch)</h2>
    <p>Ziel dieses Pitch-Projekt war es, junge Menschen für ein Studium in Baden-Württemberg zu begeistern. Vor allem MINT-Berufen sollten in den Mittelpunkt gerückt werden. Einige Beispiele zeigen die Wandelbarkeit unserer Idee und thematisieren auch Berufe außerhalb des MINT-Felds.</p>
    <p>Da die Kampagne unter dem Dach von “The Länd” stattfinden sollte, haben wir uns nicht nur textlich, sondern auch gestalterisch daran orientiert.</p>
    <p>Die Idee: In unserer schnelllebigen Zeit gibt es Hypes wie Sand am Meer. Statt jedem Hype nachzulaufen, sollte man nach den Trends Ausschau halten, die wirklich etwas bewegen.</p>
    <p>Megatrends wie Nachhaltigkeit und Urbanität formen unsere Gesellschaft und sollten deshalb nicht verpasst werden. Sie brauchen Menschen, die sie mitgestalten wollen. Wir müssen #Tränds setzen.</p>
    <p>Enstanden sind 18:1, Social Media Assets und Sonderideen. Am Konzept, der Ideenfindung und den entstandenen Headlines habe ich im Projektteam mitgearbeitet.</p>

    <vueper-slides
          bullets-outside
          :fixed-height="'.8vh'"
          :gap="5"
          slide-image-inside
          :touchable="false"
          class="no-shadow"
      >
      <vueper-slide
          v-for="(slide, i) in images"
          :key="i"
          :image="'/' + password + slide.image"
          :link="'/' + password + slide.link"
      />
    </vueper-slides>

  </div>
</template>

<style scoped lang="scss">

</style>