<script setup>
import {computed, defineProps, onMounted, onUnmounted, ref, watch} from 'vue'
import {useRoute, useRouter} from 'vue-router'

  const props = defineProps({
    path: {
      type: String,
    },
    backPath: {
      type: String,
    },
    img: {
      type: String,
    },
    width: {
      type: Number,
    },
    height: {
      type: Number,
    },
    duration: {
      type: Number,
      default: .7,
    },
    color: {
      type: String,
      default: '#1a1a1a',
    },
  })


  const x = ref(0)
  const y = ref(0)

  const width = ref(0)
  const height = ref(0)


  // const show = ref(false)
  // function updateShow() {
  //   show.value = window.location.pathname === props.pathname
  // }
  // updateShow()
  const route = useRoute()
  const router = useRouter()
  const show = computed(() => route.path.startsWith(props.path))


  const windowWidth = ref(window.innerWidth)
  const windowHeight = ref(window.innerHeight)

  function expand() {
      width.value = windowWidth.value
      height.value = windowHeight.value
      x.value = -parent.value.offsetLeft
      y.value = -parent.value.offsetTop
  }
  function shrink() {
    width.value = parent.value.clientWidth
    height.value = parent.value.clientHeight
    x.value = 0
    y.value = 0
  }

  function updateWindowDimensions() {
    windowWidth.value = window.innerWidth
    windowHeight.value = window.innerHeight
    if (show.value) {
      expand()
    } else {
      shrink()
    }
  }


  onMounted(() => {
    window.addEventListener('resize', updateWindowDimensions)
    console.log(parent.value)
    if (show.value) {
      expand()
    } else {
      shrink()
    }
  })


  onUnmounted(() => window.removeEventListener('resize', updateWindowDimensions))

  const parent = ref()
  watch(() => show.value, v => {
    if (v) {
      expand()
    } else {
      shrink()
    }
  })


const zi = ref()
  watch(() => route.path, (n, o) => {
    if (n === props.path || o === props.path) {
      zi.value = 300
    } else {
      zi.value = 0
    }
    if (n !== props.path) {
      setTimeout(() => zi.value = 0, 500)
    }
  })


</script>

<template>
  <div
    class="parent"
    ref="parent"
  >
    <div
      class="container"
      :class="{'show': show}"
      @click="() => {if (!show) router.push(path)}"
      :style="{
        'width': width + 'px',
        'height': height + 'px',
        'top': y + 'px',
        'left': x + 'px',
        'z-index': zi,
        'background-image': img,
      }"
    >
      <div
        class="background"
        :class="{'blur': show}"
        :style="{'background-image': img}"
      />
      <div
        class="background-shadow"
        :class="{'show': show}"
      />
      <div
        class="content"
        :class="{'show': show}"
      >
        <a
          class="about-button ripple-back-button"
          @click.prevent.stop="router.push(backPath)"
        >{{ route.path.includes('traend') ? 'Bäck' : 'Back' }}</a>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

.ripple {
  overflow: hidden;
  width: 0;
  height: 0;
  position: fixed;
}

.parent {
  overflow: visible;
  position: relative;
}

.container {
  overflow: hidden;
  position: absolute;

  transition-property: width, height, top, left, z-index;
  transition-timing-function: ease-in-out;
  transition-duration: .8s;
  transition-delay: .2s;


  .background {
    position: absolute;
    top: -8px;
    left: -8px;
    right: -8px;
    bottom: -8px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    animation: deblur 1s linear forwards;
  }

  .background-shadow {
    position: absolute;
    top: -8px;
    left: -8px;
    right: -8px;
    bottom: -8px;
    opacity: 0;
    background: #1a1a1aee;

    transition-property: opacity;
    transition-timing-function: linear;
    transition-duration: .7s;
    transition-delay: .5s;

    &.show {
      opacity: 1;
      transition-delay: .0s;
    }
  }

  &.show {
    z-index: 300;
    transition-delay: 0s;
    transition-duration: 1s;
    .background {
      animation: blur .7s linear forwards;
    }
  }

  @keyframes blur {
    0% { filter: blur(0px);}
    50% { filter: blur(4px)}
    100% { filter: blur(8px)}
  }
  @keyframes deblur {
    0% { filter: blur(8px);}
    50% { filter: blur(4px)}
    100% { filter: blur(0px)}
  }

  .content {
    color: #e1e1e1;
    pointer-events: none;
    position: relative;
    opacity: 0;
    height: 100%;
    width: 100%;
    overflow: scroll;
    z-index: 200;
    transition-property: opacity;
    transition-timing-function: linear;
    transition-duration: .3s;
    transition-delay: 0s;

    &.show {
    pointer-events: inherit;
      transition-delay: .7s;
      opacity: 1;
    }
  }
}

</style>