<template>
  <div class="content-container cv">
    <div>
      <h2>CV</h2>
      <img src="/jessi_book.webp" class="pic" />
      <p>
        Anders als meine geradlinige Art, weist mein Lebenslauf Ecken und Kanten auf. Yeeha!
        Das feiere ich. Ich konnte Erfahrung in der Fotografie, in Sachen Styling und vielem mehr sammeln.
        Alle Skills, die ich mir in den verschiedenen Bereichen angeeignet habe,
        fließen in meine Arbeit ein und helfen mir – manchmal auch sehr überraschend –
        im Berufsalltag als Copywriterin.
      </p>

    </div>
    <section class="timeline">
      <div class="container">

<!--        <div class="timeline-item">-->
<!--          <div class="timeline-content edu">-->
<!--            <div class="date">2009 - 2012</div>-->
<!--            <div class="title">Abitur am Wirtschaftsgymnasium</div>-->
<!--            <div class="caption">Handelslehranstalt Bruchsal</div>-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="timeline-item">-->
<!--          <div class="timeline-content edu">-->
<!--            <div class="date">Sept. 2013 – Juli 2016</div>-->
<!--            <div class="title">Ausbildung zur Fotomedienfachfrau</div>-->
<!--            <div class="caption">Foto- und Medienforum Kiel<br>Klick-Fotostudio Berlin</div>-->
<!--            <p>Schwerpunkt Porträtfotografie</p>-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="timeline-item">-->
<!--          <div class="timeline-content">-->
<!--            <div class="date">Juli 2016 – Mai 2017</div>-->
<!--            <div class="title">Portraitfotografin</div>-->
<!--            <div class="caption">Klick-Fotostudio Berlin</div>-->
<!--              <p>Planung und Durchführung von Fotoshoots in den Bereichen:</p>-->
<!--            <ul>-->
<!--              <li>Portrait</li>-->
<!--              <li>Gruppenaufnahmen</li>-->
<!--              <li>Hochzeit</li>-->
<!--            </ul>-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="timeline-item">-->
<!--          <div class="timeline-content">-->
<!--            <div class="date">Mai 2017 – Okt. 2018</div>-->
<!--            <div class="title">Fashion Stylistin</div>-->
<!--            <div class="caption">Curated Shopping GmbH</div>-->
<!--            <ul>-->
<!--              <li>Erstellung von Outfits, Kundenberatung und Verkauf</li>-->
<!--              <li>Pflege des eigenen Portfolios</li>-->
<!--              <li>Assistent Teamlead</li>-->
<!--            </ul>-->
<!--          </div>-->
<!--        </div>-->

        <div class="timeline-item">
          <div class="timeline-content edu">
            <div class="date">Okt. 2018 – März 2020</div>
            <div class="title">Studium der Sozialwissenschaften</div>
            <div class="caption">Universität Stuttgart</div>
          </div>
        </div>

        <div class="timeline-item">
          <div class="timeline-content edu">
            <div class="date">Sept. 2020 – Sept. 2021</div>
            <div class="title">Ausbildung zur Copywriterin und Konzepterin</div>
            <div class="caption">Kreativ Kader Düsseldorf<br> Zum goldenen Hirschen Köln</div>
          </div>
        </div>

        <div class="timeline-item">
          <div class="timeline-content">
            <div class="date">Sept. 2020 – Aug. 2021</div>
            <div class="title">Trainee Copywriting</div>
            <div class="caption">Zum goldenen Hirschen</div>
            <ul>
              <li>Unterstützung bei der Erstellung und Konzepten</li>
              <li>Selbstständiges Schreiben von Headlines und Shortcopys</li>
              <li>Unterstützung bei der Erstellung von Skripten für TV- und Radio-Spots</li>
            </ul>
          </div>
        </div>

        <div class="timeline-item">
          <div class="timeline-content">
            <div class="date">Sept. 2021 – Juli 2022</div>
            <div class="title">Junior Copywriterin Online-Redaktion</div>
            <div class="caption">Zum goldenen Hirschen</div>
            <ul>
              <li>Recherche und Erstellung von Artikeln für das OBI Onlinemagazin</li>
              <li>Texterstellung und Planung von App-Inhalten</li>
              <li>Redaktionelle Planung und Umsetzung von Contant Campaings für das Onlinemagazin</li>
              <li>CMS Pflege</li>
              <li>Erstellung von Headlines und Shortcopys für Beilagen</li>
            </ul>
          </div>
        </div>

        <div class="timeline-item">
          <div class="timeline-content">
            <div class="date">Aug. 2021 – Aug. 2023</div>
            <div class="title">Junior Online-Redakteurin</div>
            <div class="caption">YAEZ GmbH</div>
            <ul>
              <li>Texterstellung und Konzeption von Social Media Formaten</li>
              <li>Redaktionelle Planung und Umsetzung von Content</li>
              <li>CMS Pflege</li>
              <li>Erstellung von Headlines und Shortcopys</li>
            </ul>
          </div>
        </div>

        <div class="timeline-item">
          <div class="timeline-content">
            <div class="date">Aug. 2023 – heute</div>
            <div class="title">Junior Copywriterin</div>
            <div class="caption">Scholz & Friends</div>
            <ul>
              <li>Mitarbeit an der Konzeption von Kampagnen</li>
              <li>Erstellung von Headlines und Shortcopys</li>
              <li>Erstellung Social Media Assets</li>
              <li>Erstellung von weihnachtlichen Gedichten für geschäftliche Weihnachtskarten</li>
            </ul>
          </div>
        </div>

      </div>
    </section>



    <h3>Meine Fähigkeiten</h3>
      <ul>
        <li>Texterstellung im Bereich Short- und Longcopy</li>
        <li>Recherche</li>
        <li>MS Office</li>
        <li>Magnolia CMS</li>
        <li>Konzeption</li>
        <li>Fotografie</li>
        <li>Photoshop</li>
        <li>Sehr gute Englischkenntnisse</li>
        <li>Grundkenntnisse in Spanisch</li>
      </ul>
  </div>
</template>

<style lang="scss" scoped>
.cv {
  text-align: left;
  //color: #1a1a1a;
  .pic {
    float: left;
    margin-right: 32px;
    margin-bottom: 32px;
  }

  /* The actual timeline (the vertical ruler) */
}

$primary: #3F51B5;
$dark-primary: #303F9F;
$light-primary: #e1e1e1;
$text: #e1e1e1;
$primary-text: #212121;
$secondary-text: #757575;
$accent: #656565;

section {
  padding: 100px 0;
}

.timeline {
  clear: both;
  position: relative;

  &::before {
    content: '';
    background: $light-primary;
    width: 5px;
    height: 95%;
    position: absolute;
    left: 0;
    //left: 50%;
    //transform: translateX(-50%);
  }
}

.timeline-item {
  width: 100%;
  margin-left: 21px;
  margin-bottom: 36px;

  .timeline-content {
    position: relative;
    //width: 40%;
    //max-width: 40%;
    padding: 40px 30px 20px 30px;
    border-radius: 0;
    background: $accent;
    * {
      z-index: 2;
      position: relative;
    }

    &.edu {
      &::before {
        content: ' ';
        position: absolute;
        top: 12px;
        right: 12px;
        width: 120px;
        height: 120px;
        opacity: .15;
        background-image: url("/edu.svg");
        background-size: contain;
        background-repeat: no-repeat;
        transform: rotate(15deg);
      }
    }

    //&::after {
    //  content: '';
    //  position: absolute;
    //  border-style: solid;
    //  width: 0;
    //  height: 0;
    //  top: 30px;
    //  right: -15px;
    //  border-width: 10px 0 10px 15px;
    //  border-color: transparent transparent transparent $accent;
    //}

    &::after {
      content: '';
      position: absolute;
      border-style: solid;
      width: 0;
      height: 0;
      top: 30px;
      left: -15px;
      border-width: 10px 15px 10px 0;
      border-color: transparent $accent transparent transparent;
    }

    .date {
      display: inline-block;
      padding: 10px;
      position: absolute;
      top: 0;
      left: 0;
    }

    .title {
      font-size: 1.5em;
      line-height: 1.2em;
      margin-top: 18px;
      font-weight: bold;
    }

    .caption {
      line-height: 1.2em;
      //margin-top: -18px;
      font-weight: bold;
    }

    p {
      padding: 0 20px;
    }

    a {
      margin-left: 20px;
    }
  }

  //&:nth-child(odd) {
  //
  //  .timeline-content {
  //    float: right;
  //
  //    .date {
  //      right: auto;
  //      left: 0;
  //    }
  //
  //    &::after {
  //      content: '';
  //      position: absolute;
  //      border-style: solid;
  //      width: 0;
  //      height: 0;
  //      top: 30px;
  //      left: -15px;
  //      border-width: 10px 15px 10px 0;
  //      border-color: transparent $accent transparent transparent;
  //    }
  //  }
  //}

  &::after {
    content: '';
    display: block;
    clear: both;
  }
}







//@media screen and (max-width: 768px) {
//
//  .timeline {
//
//    &::before {
//      left: 50px;
//    }
//
//    .timeline-content {
//      max-width: 100%;
//      width: auto;
//      margin-left: 70px;
//    }
//
//    .timeline-item {
//
//      &:nth-child(odd) {
//        .timeline-content {
//          float: none;
//        }
//      }
//
//      &:nth-child(even) {
//        .timeline-content {
//          &::after {
//            content: '';
//            position: absolute;
//            border-style: solid;
//            width: 0;
//            height: 0;
//            top: 30px;
//            left: -15px;
//            border-width: 10px 15px 10px 0;
//            border-color: transparent $accent transparent transparent;
//          }
//        }
//
//      }
//    }
//  }
//
//}
</style>