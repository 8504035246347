<template>
  <div class="home">
    <div></div>
    <div class="img-container">
      <img src="/jessi2_90_2160.webp" />
    </div>

    <div class="name-container serif">
      <div class="left">Jessica</div>
      <div class="right">Kohler</div>
    </div>


    <ripple path="/about">
      <template #activator="{ toggle }">
        <a class="about-button" @click="toggle">About</a>
      </template>
      <template #default="{ toggle }">
        <a class="about-button ripple-back-button" @click="toggle">Back</a>
        <About />
      </template>
    </ripple>

    <ripple path="/portfolio">
      <template #activator="{ toggle }">
        <a class="portfolio-button" @click="toggle">Portfolio</a>
      </template>
      <template #default="{ toggle }">
        <a class="portfolio-button ripple-back-button" @click="toggle">Back</a>
        <Portfolio />
      </template>
    </ripple>

  </div>
</template>

<script>
import About from "@/views/About";
import Portfolio from "@/views/Portfolio";
import ripple from "@/components/ripple";
import {ref} from "vue";

export default {
  name: 'Home',
  components: {
    About, Portfolio, ripple,
  },
  setup() {
    const ripple = ref({
      x: 0,
      y: 0,
      active: false,
    })
    return {ripple}
  },
  methods: {
    toggleRipple(e) {
      this.ripple.x = e.clientX
      this.ripple.y = e.clientY
      this.ripple.active = !this.ripple.active
    }
  },
}
</script>

<style lang="scss">

.home {
  //position: absolute;
  width: 100vw;
  max-width: 100%;
  max-height: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
}

.name-container {
  color: #e1e1e1;
  min-width: 40vw;
  margin: 0;
  position: absolute;
  top: 60%;
  left: 40%;
  transform: translateY(-50%) translateX(-50%);
  mix-blend-mode: exclusion;
  font-size: 10vw;
  line-height: .9em;
}

.img-container {
  margin: 0;
  padding: 0;
  //position: relative;
  //bottom: 0;
  overflow: hidden;
  //left: 60%;
  //transform: translateX(-50%);
  user-select: none;
  img {
    position: relative;
    bottom: -10px;
    margin: 0;
    padding: 0;
    height: 80vh;
    //box-shadow: 0px 0px 5px #000;
  }
  //&:hover {
  //  z-index: 1000;
  //}

}

.ripple-button {
  z-index: 50;
  position: fixed;
  color: inherit;
  font-size: 1.5em;
  text-decoration: none;

  &::after {
    background-color: #1a1a1a;
    position: absolute;
    bottom: -3px;
    left: 0;
    content: '';
    width: 100%;
    height: 1px;
    transform: scaleX(0);
    transform-origin: right top;
    transition: transform .3s;
  }
  &:hover::after {
    -webkit-transform-origin: left top;
    transform-origin: left top;
    transform: scaleX(1);
  }
}

.ripple-back-button {
  z-index: 300;
  &::after {
    background-color: #e1e1e1;
  }
}

.about-button {
  @extend .ripple-button ;
  top: 75px;
  left: 24px;
  transform: rotate(-90deg);
}

.portfolio-button {
  @extend .ripple-button ;
  top: 48px;
  right: 75px;
}


</style>