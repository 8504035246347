<script setup>
import {ref, defineProps, computed} from 'vue'
import {VueperSlide, VueperSlides} from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
// import 'vue-plyr/dist/vue-plyr.css'

  defineProps({
    password: String,
  })

  const magazins = ref([{
      image: '/magazin_freier_himmel.webp',
      link: '/magazin_freier_himmel.pdf',
    }, {
      image: '/magazin_kino.webp',
      link: '/magazin_kino.pdf',
    }, {
      image: '/magazin_schlafzimmer.webp',
      link: '/magazin_schlafzimmer.pdf',
    }, {
      image: '/magazin_gartenrestaurant.webp',
      link: '/magazin_gartenrestaurant.pdf',
    }, {
      image: '/magazin_gartensaison.webp',
      link: '/magazin_gartensaison.pdf',
    }, {
      image: '/magazin_drinnen_gaertnern.webp',
      link: '/magazin_drinnen_gaertnern.pdf',
    }, {
      image: '/magazin_kueche.webp',
      link: '/magazin_kueche.pdf',
    }, {
      image: '/magazin_strahlen.webp',
      link: '/magazin_strahlen.pdf',
    }])

    const articles = ref([{
      image: '/Artikel_Hobbyraum_interview.webp',
      link: '/Artikel_Hobbyraum_interview.html',
    }, {
      image: '/Artikel_schwebender_Weihnachtsbaum.webp',
      link: '/Artikel_schwebender_Weihnachtsbaum.html',
    }, {
      image: '/Artikel_wintergrillen.webp',
      link: '/Artikel_wintergrillen.html',
    }])

    const appContents = ref([{
      image: '/J_05073.webp',
      link: '/J_05073.html',
    }, {
      image: '/J_05088.webp',
      link: '/J_05088.html',
    }, {
      image: '/J_05094.webp',
      link: '/J_05094.html',
    }, {
      image: '/J_05091.webp',
      link: '/J_05091.html',
    }, {
      image: '/J_05092.webp',
      link: '/J_05092.html',
    }])

    const videos = ref([{
        title: '🇨🇿 Gartenprojekte mit dem Frühplaner Vorteil',
        url: '/2021-Early-Birds_30s_16x9_TVC_CZ_small.mp4',
        subs: '/2021-Early-Birds_30s_16x9_TVC_CZ_small.vtt',
        playing: false,
      }, {
        title: '🇨🇿 Kataloge und Lieferung',
        url: '/2021-Catalogues_30s_16x9_TVC_CZ_small.mp4',
        subs: '/2021-Catalogues_30s_16x9_TVC_CZ_small.vtt',
        playing: false,
      }, {
        title: '🇨🇿 Pflanzen',
        url: '/2021-Plants-F1_30s_16x9_TVC_CZ_small.mp4',
        subs: '/2021-Plants-F1_30s_16x9_TVC_CZ_small.vtt',
        playing: false,
    }, {
        title: '🇨🇿 Urlaub zu Hause',
        url: '/2021-VaH_30s_16x9_TVC_CZ_small.mp4',
        subs: '/2021-VaH_30s_16x9_TVC_CZ_small.vtt',
        playing: false,
    }])

  const magazinHeight = computed(() => Math.min(window.innerWidth / 720 * 509, 509))
  const articleHeight = computed(() => Math.min(window.innerWidth / 720 * 371, 371))
</script>

<template>
   <div class="section">
      <h2>Print Beilagen</h2>
      <p>
        Diese Seiten wurden im Rahmen der Umgestaltung der OBI Beilage erstellt. Ziel war es, mehr Content
        und Enabling auf die Seiten zu packen. An diesen Seiten habe ich von der Konzeption bis zur
        Druckfreigabe mitgearbeitet.
      </p>

      <vueper-slides
          :slide-ratio="1 / 8"
          :fixed-height="magazinHeight + 'px'"
          bullets-outside
          :gap="5"
          slide-image-inside
          :touchable="false"
          class="no-shadow"
      >
        <vueper-slide
            v-for="(slide, i) in magazins"
            :key="i"
            :image="'/' + password + slide.image"
            :link="'/' + password + slide.link"
        />
      </vueper-slides>
    </div>

    <div class="section">
      <h2>Funkspot</h2>
      <p>
        Ziel des Funkspots war es, in der kalten Jahreszeit Lust auf ein eigenes Spa zu machen und dabei den
        Frühplanerrabatt zu bewerben. Diesen Spot habe ich von der ersten Idee bis zur Sprachaufnahme mit begleitet. </p>
      <vue-plyr :options="{loadSprite: false, iconUrl: '/plyr.svg'}">
        <audio controls crossorigin class="plyr" style="width:100%;">
          <source :src="'/' + password + '/85020200267-010_OBI_POS_Badplaner_-_HeyObi_15ProzentAktion_DE_27sek.mp3'" type="audio/mp3" />
        </audio>
      </vue-plyr>
    </div>

    <div class="section">
      <h2>TVC</h2>
      <p>
        Die folgenden 30 Sekünder sind im Rahmen der Frühjahrskampagne 2021 für Tschechien, Ungarn und die Slowakei
        entstanden. Sie wurden aus einem bestehenden Vignettenfilm neu zusammengeschnitten. Dabei war ich vom Schreiben
        des Voice Overs über das Basteln des Storyboards an allen Schritten beteiligt.
      </p>
      <div class="video-container">
        <div class="video" v-for="video in videos" :key="video.url">
          <vue-plyr
            :options="{
              loadSprite: false,
              iconUrl: '/plyr.svg',
              captions: {
                active: true,
                language: 'de',
              },
            }"
            @play="video.playing = true"
            @pause="video.playing = false"
          >
            <video controls crossorigin playsinline>
              <source :src="'/' + password + video.url" type="video/mp4" />
              <track
                  v-if="video.subs"
                  default
                  kind="captions"
                  label="Deutsche Untertitel"
                  :src="'/' + password + video.subs"
                  srclang="de"
              />
            </video>
          </vue-plyr>
          <div v-if="!video.playing" class="video-title">{{ video.title }}</div>
        </div>
      </div>
    </div>

    <div class="section">
      <h2>Online Magazin</h2>
      <p>Für das „So geht das” OBI Magazin habe ich diese Artikel recherchiert und selbstständig geschrieben.</p>
      <vueper-slides
          :slide-ratio="1 / 8"
          :fixed-height="articleHeight + 'px'"
          bullets-outside
          :gap="5"
          slide-image-inside
          :touchable="false"
          class="no-shadow"
      >
        <vueper-slide
            v-for="(slide, i) in articles"
            :key="i"
            :image="'/' + password + slide.image"
            :link="'/' + password + slide.link"
        />
      </vueper-slides>
    </div>

    <div class="section">
      <h2>heyOBI App Texte</h2>
      <p>
        Meine Aufgabe ist es, die aktuellen Themenblöcke von CREATE! by OBI in die App zu bringen. Alle zwei Wochen
        plane ich eine Collection und schreibe die Texte dafür. Hier eine kleine Auswahl:
      </p>
      <vueper-slides
          :slide-ratio="1 / 8"
          :fixed-height="appHeight + 'px'"
          bullets-outside
          :gap="5"
          slide-image-inside
          :touchable="false"
          class="no-shadow"
      >
        <vueper-slide
            v-for="(slide, i) in appContents"
            :key="i"
            :image="'/' + password + slide.image"
            :link="'/' + password + slide.link"
        />
      </vueper-slides>
    </div>
</template>

<style lang="scss">


</style>