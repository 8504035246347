<template>
  <Home />
  <cursor />
</template>

<script>
import cursor from "@/components/cursor";
import Home from "@/views/Home";

export default {
  name: 'App',
  components: {
    cursor, Home
  }
}
</script>

<style lang="scss">
@import 'normalize.css/normalize';

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/Inter.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Monea Alegante';
  font-style: normal;
  font-weight: 400;
  src: local('Monea Alegante'), url('/MoneaAlegante.woff') format('woff');
}


.content-container {
  max-width: 932px;
  display: inline-block;
  padding-bottom: 48px;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 96px
}

.serif {font-family: 'Monea Alegante', serif;}
.right {text-align: right;}
.left {text-align: left;}
.center {text-align: center;}
.inline {display: inline-block;}

#app {
  cursor: none;
  font-family: Inter, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #1a1a1a;
  background-color: #eaeaea;
  //background-image: url("/public/noisy-texture-100x100-o11-d16-c-ffffff-t0.png");
  min-height: 100vh;
  background-image: url("/bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  font-size: 1.2em;
  line-height: 1.7em;
}




</style>
