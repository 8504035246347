<script setup>
import {computed, onMounted, ref} from 'vue'
import ImgRipple from '@/components/imgRipple.vue'

import Obi from '@/components/obi.vue'
import Voest from '@/components/voest.vue'
import Traend from '@/components/traend.vue'

const password = ref('')
const checked = ref(false)
const wrong = ref(false)

const obiImg = computed(() => 'url("/' + password.value + '/obi.webp")')
const voestImg = computed(() => 'url("/' + password.value + '/voest.webp")')
const traendImg = computed(() => 'url("/' + password.value + '/traend.webp")')


async function check(pw, initial = false) {
  if (pw.length >= 3) {
    const checkfile = await (await fetch(`/${pw}/check`)).text()
    if (checkfile.startsWith('ok')) {
      sessionStorage.setItem('password', pw)
      password.value = pw
      checked.value = true
    } else {
      if (!initial) {
        wrong.value = true
      }
    }
  } else {
    if (!initial) {
      wrong.value = true
    }
  }
}

onMounted(() => {
  const storedPw = sessionStorage.getItem('password')
  if (storedPw) check(storedPw, true)
})

</script>

<template>
  <div class="content-container" v-if="checked">
<!--    <obi :password="password" />-->
    <div style="display: flex;">
      <img-ripple
        back-path="/portfolio"
        path="/portfolio/obi"
        :img="obiImg"
        :style="{
          'width': '500px',
          'height': '610px',
          'margin': '10px 0',
        }"
      >
        <div class="content-container">
          <obi :password="password" />
        </div>
    </img-ripple>
      <div>
        <img-ripple
          back-path="/portfolio"
          path="/portfolio/voest"
          :img="voestImg"
          :style="{
            'width': '300px',
            'height': '300px',
          'margin': '10px',
          }"
        >
          <div class="content-container">
            <voest :password="password" />
          </div>
        </img-ripple>
        <img-ripple
          back-path="/portfolio"
          path="/portfolio/traend"
          :img="traendImg"
          :style="{
            'width': '300px',
            'height': '300px',
          'margin': '10px',
          }"
        >
          <div class="content-container">
            <traend :password="password" />
          </div>
        </img-ripple>
      </div>


    </div>
  </div>

  <div class="content-container password" v-else>
    <h2>Bitte Passwort eingeben</h2>
    <div>
      <input
          v-model="password"
          placeholder="Passwort"
          @keyup.enter="check(password)"
          @input="wrong = false"
          ref="pwInput"
      >
    </div>
    <div v-if="wrong" class="wrong">Dieses Passwort ist leider nicht richtig.</div>
    <button type="button" @click="check(password)">Bestätigen</button>
  </div>

</template>



<style lang="scss">

.password {
  input {
    border-radius: 0;
    padding: 8px 16px;
    border: 1px solid #e1e1e1;
    color: #e1e1e1;
    background-color: transparent;
    text-align: center;
  }

  button {
    margin-top: 16px;
    border-radius: 0;
    padding: 8px 16px;
    border: 1px solid #e1e1e1;
    color: #1a1a1a;
    background-color: #e1e1e1;
  }
}



.wrong {
  color: #d92a2a;
  font-size: smaller;
}

.video-container {
  display: grid;
  grid-template-columns: 48% 48%;
  grid-template-rows: auto auto;
  grid-gap: 4%;
  .video {
    position: relative;
    cursor: auto;
    video {
      max-width: 100%;
    }
    .video-title {
      position: absolute;
      font-weight: bold;
      top: 6px;
      left: 12px;
      text-shadow: black 0px 0px 3px;
    }
  }
}
.video {
  --plyr-audio-control-color: #e1e1e1;
  --plyr-audio-controls-background: transparent;
  --plyr-color-main: #ff7e08;
}


.section {
  display: block;
  text-align: left;
  margin-bottom: 196px;
}

.carousel__item {
  img {
    background-color: white;
    max-width: 70vw;
    max-height: 70vh;
  }
}

.vueperslides--fixed-height {
  height: 500px;
}


.vueperslides__arrow {
  mix-blend-mode: difference;
}

.vueperslide__image{
  background-size: contain;
  background-repeat: no-repeat;
}

</style>